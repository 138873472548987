<template>
  <div v-if="isOpen">
    <Modal
      :value="isOpen"
      class-name="modal-pay"
      width="816"
      :closable="true"
      :mask-closable="false"
      footer-hide
      @on-visible-change="handleChangeVisible"
    >
     <div class="pay-wrap">
        <div class="pay-title"></div>
        <div class="pay-label">
          请选择<span>VIP</span>套餐
        </div>
        <div class="pay-type-wrap">
          <div class="pay-type-item"  v-for="(item,index) in payTypeList" :key="index" @click="selectOrderType(item,index)" :class="{'active':activeIndex===index,'disabled':existPreferentialOrder && index ===0}">
            <img v-if="index === 0" class="xinke-icon" src="../assets/images/vip/xinke.png" alt="">
            <span class="pay-time">{{item.time}}</span>
            <span class="pay-price"><span>¥</span>{{item.price}}</span>
            <span class="pay-desc">（{{item.desc}}）</span>
            <img v-if="activeIndex === index" class="select1-icon" src="../assets/images/vip/select1.png" alt="">
          </div>
        </div>
        <div class="pay-type-info-wrap">
          <div class="pay-type-info">
            <p class="pay-type-item">
              应付金额：<span class="pay-price-wrap">¥<span class="pay-price">{{payOrderObj.price}}</span>
              </span>
            </p>
            <p class="pay-type-item">
              开通时长：
              <span>{{payOrderObj.time}}（{{maturityTimeStr}}到期）</span>
            </p>
            <p class="pay-type-item">
              购买账号：{{userInfo.phone}}
            </p>
            <div class="pay-type-choose-wrap">
              <span class="pay-type-label">
              支付方式：
              </span>
              <div class="pay-type-choose">
                <span class="pay-type-choose-item" v-for="(item,index) in payType" :key="index" @click="selectPayType(item,index)" :class="selectIndex===index?'select':''">
                  <img class="pay-type-icon" :src="item.icon" alt="">
                  <span class="pay-type-name" >{{item.name}}</span>
                  <img v-if="selectIndex === index" class="select2" src="../assets/images/vip/select2.png" alt="">
                </span>
              </div>
            </div>
          </div>
          <div class="pay-type-qrcode-wrap" :class="selectIndex===0?'weixin':'zhifub'">
              <div id="qrcode" ref="qrcode"></div>
              <Spin v-if="isLoading" fix size="small"></Spin>
              <div class="pay-type-qrcode-prompt">
                <img :src="curPayTypeIcon" alt="">
                  <!-- {{selectIndex===0?'请用微信扫一扫':'请用zhifubao扫一扫'}} -->
                  {{'请用'+payTypeObj.name+'扫一扫'}}
              </div>
          </div>
        </div>
     </div>
    </Modal>
  </div>
</template>
<script>
import {User} from "@/service"
import { mapMutations } from "vuex";
import QRCode from 'qrcodejs2'
import dayjs from "dayjs";
export default {
  props:["isShow"],
  data () {
    return {
      isOpen:false,
      payTypeList:[
        {
          price:1.99,
          desc:"仅限一次",
          time:"7天",
          productCode:"qianlimu-1",
          // maturity:"7"
        },
        {
          price:399,
          desc:"¥399/月",
          time:"1个月",
          productCode:"qianlimu-2"
        },
        {
          price:1017,
          desc:"¥339/月",
          time:"3个月",
          productCode:"qianlimu-3"
        },
        {
          price:3588,
          desc:"¥299/月",
          time:"1年",
          productCode:"qianlimu-4"
        }
      ],
      activeIndex:0,
      payType:[
        {
          name:"微信支付",
          type:1,
          icon:require("@/assets/images/vip/weixin.png")
        },
        {
          name:"支付宝支付",
          type:2,
          icon:require("@/assets/images/vip/zhifub.png")
        }
      ],
      selectIndex:0,
      curPayTypeIcon:require("@/assets/images/vip/weixin.png"),
      payTypeObj:null,
      payOrderObj:null,
      isLoading:false,
      qrCode:null,
      existPreferentialOrder:false,
      userInfo:null,
      outTradeNo:"",
      timer:null,
      timer2:null,
      maturityTimeStr:""
    }
  },
  watch:{
    async isShow(val){
      this.isOpen = val;
      this.selectIndex = 0;
      //  this.timer=setInterval(()=>{
      //       this.getOrderStatus()
      //     },2000)
      if(val){
        await this.getUserInfo();
         this.getOrderQrcode();
         this.curPayTypeIcon = require("@/assets/images/vip/weixin.png");
      }
      else{
        this.payTypeObj = null;
        this.payOrderObj = null;
      }
    },
    isOpen(newval) {
      if (newval) {
          this.timer=setInterval(()=>{
            this.getOrderStatus()
          },2000)
      }else{
        this.close();
        clearTimeout(this.timer);
        this.timer = null;
      }
    }
  },
  methods:{
    ...mapMutations(["setUserInfoByKey"]),
    selectOrderType(item,index){
      if(index ===0 && this.existPreferentialOrder)return
      this.activeIndex = index;
      this.payOrderObj = item;
      this.getOrderQrcode();
    },
    selectPayType(item,index){
      this.selectIndex = index;
      this.curPayTypeIcon = item.icon;
      this.payTypeObj  = item;
      this.getOrderQrcode()
    },
    async getOrderQrcode(){
      let params = {
        payType:this.payTypeObj.type,
        userID:Number(this.userInfo.userID),
        productCode:this.payOrderObj.productCode,
        productCount:1,
        productAmountTotal:this.payOrderObj.price * 100,
        orderAmountTotal:this.payOrderObj.price * 100,
        isUpgrade:false
      }
      this.isLoading = true
      let res = await User.getOrderQrcode(params)
      if(this.qrCode){
        document.getElementById("qrcode").innerHTML = "";
      }
      this.isLoading = false;
      this.getMaturityTime(this.payOrderObj)
      if(res && res.code ===200){
        this.outTradeNo = res.data.outTradeNo;
        this.getOrderStatus()
        this.qrCode = new QRCode('qrcode', {
          width: 150,
          height: 150, // 高度
          text: res.data.qrCode, // 二维码内容
          render: 'canvas', // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
          background: '#555',
          foreground: '#ff0',
        })

      }
    },
    close() {
      this.$emit("close");
    },
    handleChangeVisible(tag) {
      if (!tag) {
        this.close();
      }
    },
    async getUserInfo() {
      let res = await User.getUserInfo();
      if (res && res.code === 200) {
        this.setUserInfoByKey(["userMember", res.data.items.userMember]);
        let user = JSON.stringify(res.data.items);
        localStorage.setItem("userInfo", user);
        this.userInfo = res.data.items;
         this.existPreferentialOrder = this.userInfo.userMember.existPreferentialOrder
        if(this.userInfo.userMember.existPreferentialOrder){
          this.payTypeObj = this.payType[0];
          this.payOrderObj = this.payTypeList[1];
          this.activeIndex = 1
        }else{
           this.payTypeObj = this.payType[0];
           this.payOrderObj = this.payTypeList[0];
           this.activeIndex = 0
        }
      }
    },
    async getOrderStatus(){
      let params = {
        outTradeNo:this.outTradeNo
      }
      let res =await User.getOrderStatus(params)
      if(res && res.code === 200){
        let orderStatus = res.data.orderStatus;
        if(orderStatus === 2 || orderStatus === 4){
          clearInterval(this.timer);
          this.timer = null;
          this.close()
          this.$emit("payStatus",res.data)
        }
      }
    },
    getMaturityTime(item){
       let dd = new Date();
        let time = 0;
      if(item.time === '7天'){
        time = 7
      }else if(item.time === '1个月'){
        time = 30;
      }else if (item.time === '3个月'){
        time = 90 ;
      }else if (item.time === '1年'){
        time = 365;
      }
      let maturity = dd.setDate(dd.getDate()+time) ;
      this.maturityTimeStr = dayjs(maturity).format("YYYY-MM-DD")
    },
  },
  mounted(){
    // this.getUserInfo();
  },
  beforeDestroy(){
    clearInterval(this.timer);
    this.timer = null;
  }
}
</script>
<style  lang="less">
.modal-pay {
  display: flex;
  justify-content: center;
  align-items: center;
  .ivu-modal {
    top: 0 !important;
  }
  // .ivu-modal .ivu-modal-content {
  //   width: 400px;
  //   // height: 306px;
  //   background: #fff;
  //   box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  //   // border-radius: 2px;
  //   border: 1px solid rgba(56, 76, 108, 0.8);
  //   backdrop-filter: blur(16px);
  // }
  .ivu-modal-body {
    padding-left: 37px;
    padding-right: 37px;
    .pay-wrap{
      padding-top: 22px;
      .pay-title{
        width: 658px;
        // height: 25px;
        height: 55px;
        margin: auto;
        // background-image: url("../assets/images/vip/vip-bg.png");
        background-image: url("../assets/images/vip/vip-bg2.png");
        background-repeat: no-repeat;
        background-size: 658px 70px;
      }
      .pay-label{
        margin-top: 27px;
        font-size: 16px;
        font-weight: 400;
        color: rgba(0,0,0,0.8);
        line-height: 22px;
        span{
          color: #9C6518;
          font-size: 20px;
          margin: 0 5px;
        }
      }
      .pay-type-wrap{
        padding: 18px 0 24px 0;
        border-bottom:1px dashed rgba(0,0,0,0.13);
        display: flex;
        justify-content: space-between;
        .pay-type-item{
          padding: 20px;
          width: 170px;
          height: 128px;
          border-radius: 10px;
          border: 1px solid #D7D7D7;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          position: relative;
          .pay-time{
            font-weight: 600;
            color: #6A3611;
          }
          .pay-price{
            color: #F21717;
            line-height: 22px;
            font-size: 30px ;
            margin: 8px 0 3px 0;
            span{
            font-size: 16px;
            }
          }
          .pay-desc{
            color: rgba(0,0,0,0.4);
            font-size: 14px;

line-height: 20px;
          }
          .xinke-icon{
            position: absolute;
            top: 0;
            left: 0;
            width: 62px;
            height: 54px;
          }
          .select1-icon{
            height: 29px;
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }
      }
      .pay-type-info-wrap{
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
        padding: 0 140px 20px 114px;
        .pay-type-info{
          .pay-type-item{
            color: rgba(0,0,0,0.8);
            line-height: 22px;
            margin-bottom: 16px;
            .pay-price-wrap{
              color: #F21717;
              .pay-price{
                font-size: 30px;
              }
            }
          }
          .pay-type-choose-wrap{
            display: flex;
            justify-content: flex-start;
            .pay-type-label{

            }
            .pay-type-label{

            }
            .pay-type-choose{
              display: flex;
              flex-direction: column;
              .pay-type-choose-item{
                cursor: pointer;
                position: relative;
                margin-bottom: 8px;
                // display: inline-block;
                color: rgba(0,0,0,0.7);
                width: 165px;
                height: 36px;
                line-height: 36px;
                padding: 0 22px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-radius: 2px;
                border: 1px solid #EAEAEA;
                .pay-type-icon{
                  width: 24px;
                  height: 24px;
                  margin-right: 16px;
                }
                .select2{
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  width: 27px;
                  height: 27px;
                }
              }
              .select{
                 border: 1px solid #3519FB;
              }
            }
          }

        }
        .pay-type-qrcode-wrap{
          width: 180px;
          height: 209px;
          background: #FFFFFF;

          position: relative;
          padding: 15px;
          .pay-type-qrcode-prompt{
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 36px;
            line-height: 36px;

            font-weight: 500;
            color: rgba(0,0,0,0.7);
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }
          }
        }
        .weixin{
          border: 1px solid #0DC803;
          .pay-type-qrcode-prompt{
            background: rgba(13,200,3,0.06);
          }
        }
        .zhifub{
          border: 1px solid #3E8BFE;
          .pay-type-qrcode-prompt{
            background: rgba(62,139,254,0.06);
          }
        }
      }
    }
    .ivu-spin{
      top: 15px;
      width: 100%;
      height: 150px;
    }
  }
  .active{
    background: #FFFBF6;
    border-radius: 10px;
    border: 1px solid #FF6700;
  }
  .disabled{
    cursor: no-drop !important;
    background: #F4F4F4;
    border-radius: 10px;
    border: 1px solid #D7D7D7;
  }
}
</style>

