var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpen
    ? _c(
        "div",
        [
          _c(
            "Modal",
            {
              attrs: {
                value: _vm.isOpen,
                "class-name": "modal-pay",
                width: "816",
                closable: true,
                "mask-closable": false,
                "footer-hide": "",
              },
              on: { "on-visible-change": _vm.handleChangeVisible },
            },
            [
              _c("div", { staticClass: "pay-wrap" }, [
                _c("div", { staticClass: "pay-title" }),
                _c("div", { staticClass: "pay-label" }, [
                  _vm._v(" 请选择"),
                  _c("span", [_vm._v("VIP")]),
                  _vm._v("套餐 "),
                ]),
                _c(
                  "div",
                  { staticClass: "pay-type-wrap" },
                  _vm._l(_vm.payTypeList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "pay-type-item",
                        class: {
                          active: _vm.activeIndex === index,
                          disabled: _vm.existPreferentialOrder && index === 0,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.selectOrderType(item, index)
                          },
                        },
                      },
                      [
                        index === 0
                          ? _c("img", {
                              staticClass: "xinke-icon",
                              attrs: {
                                src: require("../assets/images/vip/xinke.png"),
                                alt: "",
                              },
                            })
                          : _vm._e(),
                        _c("span", { staticClass: "pay-time" }, [
                          _vm._v(_vm._s(item.time)),
                        ]),
                        _c("span", { staticClass: "pay-price" }, [
                          _c("span", [_vm._v("¥")]),
                          _vm._v(_vm._s(item.price)),
                        ]),
                        _c("span", { staticClass: "pay-desc" }, [
                          _vm._v("（" + _vm._s(item.desc) + "）"),
                        ]),
                        _vm.activeIndex === index
                          ? _c("img", {
                              staticClass: "select1-icon",
                              attrs: {
                                src: require("../assets/images/vip/select1.png"),
                                alt: "",
                              },
                            })
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
                _c("div", { staticClass: "pay-type-info-wrap" }, [
                  _c("div", { staticClass: "pay-type-info" }, [
                    _c("p", { staticClass: "pay-type-item" }, [
                      _vm._v(" 应付金额："),
                      _c("span", { staticClass: "pay-price-wrap" }, [
                        _vm._v("¥"),
                        _c("span", { staticClass: "pay-price" }, [
                          _vm._v(_vm._s(_vm.payOrderObj.price)),
                        ]),
                      ]),
                    ]),
                    _c("p", { staticClass: "pay-type-item" }, [
                      _vm._v(" 开通时长： "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.payOrderObj.time) +
                            "（" +
                            _vm._s(_vm.maturityTimeStr) +
                            "到期）"
                        ),
                      ]),
                    ]),
                    _c("p", { staticClass: "pay-type-item" }, [
                      _vm._v(" 购买账号：" + _vm._s(_vm.userInfo.phone) + " "),
                    ]),
                    _c("div", { staticClass: "pay-type-choose-wrap" }, [
                      _c("span", { staticClass: "pay-type-label" }, [
                        _vm._v(" 支付方式： "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "pay-type-choose" },
                        _vm._l(_vm.payType, function (item, index) {
                          return _c(
                            "span",
                            {
                              key: index,
                              staticClass: "pay-type-choose-item",
                              class: _vm.selectIndex === index ? "select" : "",
                              on: {
                                click: function ($event) {
                                  return _vm.selectPayType(item, index)
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "pay-type-icon",
                                attrs: { src: item.icon, alt: "" },
                              }),
                              _c("span", { staticClass: "pay-type-name" }, [
                                _vm._v(_vm._s(item.name)),
                              ]),
                              _vm.selectIndex === index
                                ? _c("img", {
                                    staticClass: "select2",
                                    attrs: {
                                      src: require("../assets/images/vip/select2.png"),
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "pay-type-qrcode-wrap",
                      class: _vm.selectIndex === 0 ? "weixin" : "zhifub",
                    },
                    [
                      _c("div", { ref: "qrcode", attrs: { id: "qrcode" } }),
                      _vm.isLoading
                        ? _c("Spin", { attrs: { fix: "", size: "small" } })
                        : _vm._e(),
                      _c("div", { staticClass: "pay-type-qrcode-prompt" }, [
                        _c("img", {
                          attrs: { src: _vm.curPayTypeIcon, alt: "" },
                        }),
                        _vm._v(
                          " " +
                            _vm._s("请用" + _vm.payTypeObj.name + "扫一扫") +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }