<template>
  <div class="site-wrap">
    <Header :mode="1" :isRevertIcon="true"></Header>
    <div class="main-wrap vip-main">
      <div class="page-scroll">
        <div class="tab-size">
          <div class="tab-box tab-box2">
            <div class="tab-top">89%用户的选择</div>
            <div class="tab-title">VIP会员</div>
            <div class="tab-explain">
              可使用所有模块的公开数据及标注 VIP 的数据和功能<br />
              （<span class="red">*</span>不包含亿景智图在内的应用中的产品）
            </div>
            <img
              class="tab-picture"
              :src="require('@/assets/images/vip/vip-mode-img.png')"
              alt=""
              width="512"
              height="128"
            />
            <!-- <div class="tab-price">
              <span>限时免费 ¥</span>
              <span>0</span>
              <span>/月</span>
            </div>
            <div class="tab-price2">¥399/月</div> -->
            <div class="tab-btn" @click="useNow">立即开通</div>
          </div>
          <div class="tab-box tab-box3">
            <div class="tab-top"></div>
            <div class="tab-title">SVIP会员</div>
            <div class="tab-explain">
              可使用所有模块的公开数据及<br />标注VIP、SVIP的数据和功能
            </div>
            <div class="tab-contact">
              <span>咨询电话</span>
              <span>010-86463634</span>
            </div>
            <div class="tab-btn2" @click="consultingClick(1)">立即咨询</div>
          </div>
          <div class="tab-box tab-box4">
            <div class="tab-top"></div>
            <div class="tab-title">专属定制服务</div>
            <div class="tab-explain">
              可使用所有模块的全部数据和<br />功能且支持定制化数据和服务
            </div>
            <div class="tab-contact">
              <span>咨询电话</span>
              <span>010-86463634</span>
            </div>
            <div class="tab-btn2" @click="consultingClick(2)">立即咨询</div>
          </div>
        </div>
        <div class="equity-title">会员权益</div>
        <div class="equity-table">
          <div class="table-columns">
            <div class="columns-box">功能权限</div>
            <!-- <div class="columns-box">游客版<br />（免费）</div> -->
            <div class="columns-box">普通会员<br />（免费）</div>
            <div class="columns-box">VIP会员<br />（¥299起/月）</div>
            <div class="columns-box">SVIP会员<br />咨询：010-86463634</div>
            <div class="columns-box">专属定制服务<br />咨询：010-86463634</div>
          </div>
          <div
            class="table-list"
            v-for="(item, index) in tableData"
            :key="'table' + index"
          >
            <div
              class="table-block"
              :class="item.noFuncName ? 'long-table-block' : ''"
              :style="{ height: item.height + 'px' }"
            >
              {{ item.name }}
            </div>
            <div class="table-function" v-if="!item.noFuncName">
              <div
                class="function-box"
                v-for="(func, num) in item.function"
                :key="'name' + num"
              >
                {{ func.name }}
              </div>
            </div>
            <div class="table-edition table-edition1">
              <div
                class="edition-box"
                v-for="(edition1, num1) in item.function"
                :key="'edition1' + num1"
              >
                <span
                  class="edition-box-right"
                  v-if="edition1.edition1 == '√'"
                ></span>
                <span
                  class="edition-box-wrong"
                  v-else-if="edition1.edition1 == 'x'"
                ></span>
                <span v-else>{{ edition1.edition1 }}</span>
              </div>
            </div>
            <div class="table-edition table-edition2">
              <div
                class="edition-box"
                v-for="(edition2, num2) in item.function"
                :key="'edition2' + num2"
              >
                <span
                  class="edition-box-right"
                  v-if="edition2.edition2 == '√'"
                ></span>
                <span
                  class="edition-box-wrong"
                  v-else-if="edition2.edition2 == 'x'"
                ></span>
                <span v-else>{{ edition2.edition2 }}</span>
              </div>
            </div>
            <div class="table-edition table-edition3">
              <div
                class="edition-box"
                v-for="(edition3, num3) in item.function"
                :key="'edition3' + num3"
              >
                <span
                  class="edition-box-right"
                  v-if="edition3.edition3 == '√'"
                ></span>
                <span
                  class="edition-box-wrong"
                  v-else-if="edition3.edition3 == 'x'"
                ></span>
                <span v-else>{{ edition3.edition3 }}</span>
              </div>
            </div>
            <div class="table-edition table-edition4">
              <div
                class="edition-box"
                v-for="(edition4, num4) in item.function"
                :key="'edition4' + num4"
              >
                <span
                  class="edition-box-right"
                  v-if="edition4.edition4 == '√'"
                ></span>
                <span
                  class="edition-box-wrong"
                  v-else-if="edition4.edition4 == 'x'"
                ></span>
                <span v-else>{{ edition4.edition4 }}</span>
              </div>
            </div>
            <!-- <div class="table-edition table-edition5">
              <div
                class="edition-box"
                v-for="(edition5, num5) in item.function"
                :key="'edition4' + num5"
              >
                <span
                  class="edition-box-right"
                  v-if="edition5.edition5 == '√'"
                ></span>
                <span
                  class="edition-box-wrong"
                  v-else-if="edition5.edition5 == 'x'"
                ></span>
                <span v-else>{{ edition5.edition5 }}</span>
              </div>
            </div> -->
            <div class="interval-box" v-if="!item.end"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="consulting-modal" v-if="modalCon">
      <div class="modal-mask"></div>
      <div class="modal-size">
        <div class="modal-title">会员套餐咨询</div>
        <div class="modal-content">
          留下您的联系方式，我们专业顾问会尽快与您联系
        </div>
        <div class="modal-item">
          <div class="item-name no-required">咨询版本</div>
          <Input
            v-model="fromData.vipType"
            placeholder=""
            disabled
            style="width: 280px"
          ></Input>
        </div>
        <div class="modal-item">
          <div class="item-name required">公司名称</div>
          <Input
            v-model="fromData.companyName"
            placeholder="请输入公司名称"
            style="width: 280px"
          ></Input>
        </div>
        <div class="modal-item">
          <div class="item-name required">您的姓名</div>
          <Input
            v-model="fromData.name"
            placeholder="请输入您的姓名"
            style="width: 280px"
          ></Input>
        </div>
        <div class="modal-item">
          <div class="item-name required">手机号码</div>
          <Input
            type="tel"
            v-model="fromData.phone"
            placeholder="请输入手机号码"
            maxlength="11"
            style="width: 280px"
            @on-change="phoneCheck"
          ></Input>
        </div>
        <div class="modal-item">
          <div class="item-name required">验证码</div>
          <Input
            v-model="fromData.code"
            placeholder="请输入验证码"
            style="width: 156px"
          ></Input>
          <div class="code-btn" v-if="!phoneRight">获取验证码</div>
          <div class="code-btn2" v-else @click="getPhoneCode">获取验证码</div>
          <div class="code-time" v-if="codeTimeShow">{{ count }}秒后获取</div>
        </div>
        <div class="modal-item">
          <div class="item-name no-required">微信号码</div>
          <Input
            v-model="fromData.wx"
            placeholder="请输入微信号码"
            style="width: 280px"
          ></Input>
        </div>
        <div class="modal-item">
          <div class="item-name no-required">您的职位</div>
          <Input
            v-model="fromData.position"
            placeholder="请输入您的职位"
            style="width: 280px"
          ></Input>
        </div>
        <div class="modal-btn" @click="submit">获取报价</div>
        <img
          class="modal-close"
          src="../assets/images/vip/close.png"
          alt=""
          @click="closeClick"
        />
      </div>
    </div>
    <ModalPay :isShow="isShowPayModal"  @close="isShowPayModal = false" @payStatus="payStatus"></ModalPay>
    <ModalPayInfo :isShow="isShowPayStatus" :curPayDetail="curPayDetail" @close="isShowPayStatus = false" @payAgain="payAgain"></ModalPayInfo>
  </div>
</template>

<script>
import { login } from "js/utils.js";
import { User } from "../service/user";
import ModalPay from "../components/ModalPay.vue"
import ModalPayInfo from "../components/ModalPayInfo.vue"

export default {
  metaInfo: {
    title: "会员套餐",
  },
  data() {
    return {
      fromData: {
        vipType: "", //咨询版本
        companyName: "", //公司名称
        name: "", //您的姓名
        phone: "", //手机号码
        code: "", //验证码
        wx: "", //微信号码
        position: "", //您的职位
      },

      modalCon: false,
      phoneRight: false,
      codeTimeShow: false,
      count: 60,
      timer: null,

      tableData: [
        {
          name: "品牌数据",
          height: "736",
          function: [
            {
              name: "入驻品牌数量",
              edition1: "每日可查看30个品牌详情",
              edition2: "可查看所有品牌详情",
              edition3: "可查看所有品牌详情",
              edition4: "可查看所有品牌详情",
            },
            {
              name: "品牌列表（地图模式/列表模式）",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "品牌筛选排序",
              edition1: "部分筛选排序",
              edition2: "部分筛选排序",
              edition3: "全部筛选排序",
              edition4: "全部筛选排序",
            },
            {
              name: "全国门店分布图",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "门店城市分布占比",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "在营门店列表",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "入驻购物中心列表",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "周边客群",
              edition1: "x",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "销量分析",
              edition1: "x",
              edition2: "x",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "客流分析",
              edition1: "x",
              edition2: "x",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "到访分析",
              edition1: "x",
              edition2: "x",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "到访客户画像",
              edition1: "x",
              edition2: "x",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "相似品牌推荐",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "品牌创意投放",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "品牌创意视频",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "品牌创意归因",
              edition1: "x",
              edition2: "x",
              edition3: "√",
              edition4: "√",
            },
          ],
        },
        {
          name: "品牌门店数据",
          height: "368",
          function: [
            {
              name: "入驻品牌门店数量",
              edition1: "每日可查看30个品牌的全部门店详情",
              edition2: "可查看所有品牌的所有门店详情",
              edition3: "可查看所有品牌的所有门店详情",
              edition4: "可查看所有品牌的所有门店详情",
            },
            {
              name: "配套信息",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "业态分析",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "周边客群",
              edition1: "x",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "销量分析",
              edition1: "x",
              edition2: "x",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "客流分析",
              edition1: "x",
              edition2: "x",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "到访分析",
              edition1: "x",
              edition2: "x",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "到访客户画像",
              edition1: "x",
              edition2: "x",
              edition3: "√",
              edition4: "√",
            },
          ],
        },
        {
          name: "位置数据",
          height: "184",
          function: [
            {
              name: "入驻位置数量",
              edition1: "每日可查看30个位置详情",
              edition2: "可查看所有位置详情",
              edition3: "可查看所有位置详情",
              edition4: "可查看所有位置详情",
            },
            {
              name: "配套信息",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "客群特征",
              edition1: "x",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "业态分布",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
          ],
        },
        {
          name: "商圈数据",
          height: "414",
          function: [
            {
              name: "入驻商圈数量",
              edition1: "每日可查看30个商圈详情",
              edition2: "可查看所有商圈详情",
              edition3: "可查看所有商圈详情",
              edition4: "可查看所有商圈详情",
            },
            {
              name: "商圈列表",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "商圈商业项目",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "商圈入驻品牌",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "配套信息",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "业态分析",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "周边客群分析",
              edition1: "x",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "客流分析",
              edition1: "x",
              edition2: "x",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "到访分析",
              edition1: "x",
              edition2: "x",
              edition3: "√",
              edition4: "√",
            },
          ],
        },
        {
          name: "商场数据",
          height: "460",
          function: [
            {
              name: "入驻商场数量",
              edition1: "每日可查看30个商场详情",
              edition2: "可查看所有商场详情",
              edition3: "可查看所有商场详情",
              edition4: "可查看所有商场详情",
            },
            {
              name: "商场列表",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "基础信息",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "配套信息",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "业态分析",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "业态构成",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "商户分布",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "周边客群分析",
              edition1: "x",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "客流分析",
              edition1: "x",
              edition2: "x",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "到访分析",
              edition1: "x",
              edition2: "x",
              edition3: "√",
              edition4: "√",
            },
          ],
        },
        {
          name: "城市数据",
          height: "230",
          function: [
            {
              name: "入驻城市数量",
              edition1: "每日可查看30个城市详情",
              edition2: "可查看所有城市详情",
              edition3: "可查看所有城市详情",
              edition4: "可查看所有城市详情",
            },
            {
              name: "城市列表",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "综合经济",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "商业数据",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
            {
              name: "交通数据",
              edition1: "√",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
          ],
        },
        {
          name: "导出功能",
          height: "46",
          noFuncName: true,
          function: [
            {
              name: "",
              edition1: "x",
              edition2: "√",
              edition3: "√",
              edition4: "√",
            },
          ],
        },
        {
          name: "其他定制功能和服务",
          height: "46",
          end: true,
          noFuncName: true,
          function: [
            {
              name: "",
              edition1: "x",
              edition2: "x",
              edition3: "x",
              edition4: "√",
            },
          ],
        },
      ],
      isShowPayModal:false,
      isShowPayStatus:false,
      curPayDetail:{
      }
    };
  },
   metaInfo: {
     title:"会员套餐",
    meta: [
      {
        name: "keywords",
        content:
          "千里目,大数据,商业数据,数据查询,数据分析,数据可视化,用户画像,实时客流,客流统计,品牌监测,品牌销量,业态分布,周边配套,广告创意投放,人口热力图分布,商户分析,商业营销,全域数据,全国数据,时空大数据,产业洞察,门店洞察,开店选址,开关店数据,竞争对手分析,对比分析,深度分析,分析报告,数据上图,品牌排行,智能查询,商业决策,数字化",
      },
      {
        name: "description",
        content:
          "千里目全域商业数据平台，提供查品牌、查门店、查位置、查商圈、查商场、查城市、查客流、查周边、查创意等全域多维数据的查询分析服务，以数据驱动商业发展和决策，助力企业发现商业机会。",
      },
    ],
  },
  computed: {
    isUserLogin() {
      return this.$store.state.isUserLogin;
    },
  },
  methods: {
    //立即咨询
    consultingClick(index) {
      (this.fromData = {
        vipType: "", //咨询版本
        companyName: "", //公司名称
        name: "", //您的姓名
        phone: "", //手机号码
        code: "", //验证码
        wx: "", //微信号码
        position: "", //您的职位
      }),
        (this.modalCon = true);
      if (index === 1) {
        this.fromData.vipType = "SVIP会员";
      } else if (index === 2) {
        this.fromData.vipType = "专属定制服务";
      }
    },
    closeClick() {
      this.modalCon = false;
    },

    //立即使用
    useNow() {
      if (!this.checkLogin()) {
        return;
      } else {
        // this.$Modal.info({
        //   title: "提示",
        //   width: "420px",
        //   content: "在线支付功能开发中，开通请咨询 010-86463634",
        //   closable: true,
        //   onOk: () => {},
        // });
        this.isShowPayModal = true
      }
      // this.$router.push("/index");
    },

    //检测登录
    checkLogin() {
      if (!this.isUserLogin) {
        this.$global.confirm({
          title: "提示",
          content: "请先登录",
          ok: () => {
            login();
          },
        });
        return false;
      }
      return true;
    },

    // 获取手机验证码
    async getPhoneCode() {
      let params = {
        phone: this.fromData.phone,
      };
      let res = await User.getSmsCaptcha(params);
      if (res && res.code === 200) {
        this.$Message.success("发送验证码成功");
        this.getcode();
      }
    },

    // 验证码倒计时
    getcode() {
      const times = 60; // 倒计时时间
      if (!this.timer) {
        this.count = times;
        this.codeTimeShow = true;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= times) {
            this.count--;
          } else {
            this.codeTimeShow = false;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },

    //手机号码校验
    phoneCheck() {
      let reg = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
      if (reg.test(this.fromData.phone)) {
        this.phoneRight = true;
      } else {
        this.phoneRight = false;
      }
    },

    //提交表单信息
    async submit() {
      let reg = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
      if (
        !this.fromData.companyName ||
        String(this.fromData.companyName).trim().length == 0
      ) {
        this.$Message.warning("请填写公司名称");
        return false;
      } else if (
        !this.fromData.name ||
        String(this.fromData.name).trim().length == 0
      ) {
        this.$Message.warning("请填写您的姓名");
        return false;
      } else if (
        !this.fromData.phone ||
        String(this.fromData.phone).trim().length == 0
      ) {
        this.$Message.warning("请填写手机号码");
        return false;
      } else if (!reg.test(this.fromData.phone)) {
        this.$Message.warning("请填写正确的手机号码");
        return false;
      } else if (
        !this.fromData.code ||
        String(this.fromData.code).trim().length == 0
      ) {
        this.$Message.warning("请填写验证码");
        return false;
      }

      let params = this.fromData;
      let res = await User.vipAsk(params);
      if (res && res.code === 200) {
        this.$Message.success(
          "提交成功，我们将尽快与您联系，您也可直接通过咨询电话联系我们"
        );
        this.modalCon = false;
      }
    },

    // 获取支付状态
    payStatus(data){
      this.curPayDetail = data;
      this.isShowPayStatus = true;
    },
    // 重新购买
    payAgain(){
       this.isShowPayStatus = false;
       this.isShowPayModal = true
    }
  },
  created() {

  },
  components:{
    ModalPay,
    ModalPayInfo
  }
};
</script>

<style lang="less">
.site-wrap {
  .vip-main {
    width: 100%;
    height: calc(100% - 55px);
    position: relative;
    background: #f7f8fa;

    .page-scroll {
      height: auto;
      min-height: calc(100% - 55px);
      margin: 20px auto;
      background: #ffffff;
      border-radius: 4px;
      // background-image: url("../assets/images/vip/vip-bg.png");
      background-image: url("../assets/images/vip/vip-bg2.png");
      background-size: 658px 25px;
      background-repeat: no-repeat;
      background-position: center 36px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      .tab-size {
        width: 1181px;
        height: 380px;
        margin: 0 auto;
        margin-top: 132px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        box-sizing: border-box;

        .tab-box {
          width: 262px;
          height: 354px;
          background: #fefefe;
          border-radius: 20px;
          border: 1px solid #d7d7d7;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-content: flex-start;
          flex-wrap: wrap;
          position: relative;

          .tab-top {
            width: 262px;
            height: 14px;
          }

          .tab-title {
            width: 100%;
            text-align: center;
            font-size: 24px;
            font-weight: 600;
            line-height: 33px;
            margin-top: 30px;
          }

          .tab-explain {
            width: 100%;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.6);
            line-height: 20px;
            margin-top: 10px;
            .red {
              color: #f21916;
            }
          }

          .tab-price {
            width: 100%;
            text-align: center;
            margin-top: 56px;

            span:nth-child(1) {
              color: #f21717;
              font-size: 16px;
            }

            span:nth-child(2) {
              color: #f21717;
              font-size: 50px;
              font-weight: bold;
              margin-top: 3px;
            }

            span:nth-child(3) {
              color: #000000;
              font-size: 16px;
              font-weight: 400;
            }
          }

          .tab-price2 {
            position: absolute;
            top: 192px;
            left: 65px;
            font-size: 16px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.4);
            text-decoration: line-through;
          }

          .tab-contact {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            position: absolute;
            top: 178px;
            left: 0;

            span {
              width: 100%;
              text-align: center;
            }

            span:nth-child(1) {
              font-size: 16px;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.6);
            }

            span:nth-child(2) {
              font-size: 16px;
              font-weight: bold;
              color: #000000;
            }
          }

          .tab-btn {
            width: 200px;
            height: 40px;
            margin-top: 20px;
            background: linear-gradient(315deg, #d9a36b 0%, #f8dbaf 100%);
            border-radius: 4px;
            font-size: 16px;
            font-weight: 600;
            color: #6a3611;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .tab-picture {
            margin-top: 15px;
          }

          .tab-btn:hover {
            background: linear-gradient(315deg, #d99651 0%, #ffd89d 100%);
          }

          .tab-btn2 {
            width: 200px;
            height: 40px;
            background: linear-gradient(223deg, #4f3bff 0%, #5a8eff 100%);
            border-radius: 4px;
            font-size: 16px;
            font-weight: 600;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: absolute;
            bottom: 50px;
            left: 31px;
          }

          .tab-btn2:hover {
            background: linear-gradient(223deg, #3e29f9 0%, #4982fe 100%);
          }
        }

        .tab-box1 {
          .tab-top {
            background: #b4b0d1;
          }

          .tab-title {
            color: #58477f;
          }
        }

        .tab-box2 {
          width: 556px;
          height: 380px;
          background: #fffbf6;
          border-radius: 20px;
          border: 1px solid #9c6518;
          text-align: center;

          .tab-top {
            width: 100%;
            height: 40px;
            background: #9c6518;
            text-align: center;
            line-height: 38px;
            font-size: 16px;
            font-weight: 600;
            color: #ffffff;
          }

          .tab-title {
            color: #9c6518;
          }
        }

        .tab-box3 {
          .tab-top {
            background: #394489;
          }

          .tab-title {
            color: #394489;
          }
        }

        .tab-box4 {
          .tab-top {
            background: #4aa160;
          }

          .tab-title {
            color: #4aa160;
          }
        }
      }

      .equity-title {
        width: 100%;
        font-size: 32px;
        font-weight: 500;
        color: #000000;
        line-height: 45px;
        text-align: center;
        margin-top: 60px;
      }

      .equity-table {
        margin: 30px auto;
        background: #ffffff;
        border: 1px solid #e8eaec;
        border-bottom: none;
        border-right: none;

        .table-columns {
          width: 100%;
          height: 103px;
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 500;
          color: #000000;

          .columns-box {
            width: 216px;
            height: 103px;
            background: #ffffff;
            border: 1px solid #e8eaec;
            border-top: none;
            border-left: none;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            color: #000000;
            line-height: 22px;
          }

          .columns-box:nth-child(1) {
            width: 316px;
          }

          .columns-box:nth-child(2) {
            background: rgba(180, 176, 209, 0.1);
          }

          .columns-box:nth-child(3) {
            background: rgba(156, 101, 24, 0.1);
          }

          .columns-box:nth-child(4) {
            background: rgba(60, 76, 237, 0.1);
          }

          .columns-box:nth-child(5) {
            background: rgba(57, 68, 137, 0.1);
          }
          .columns-box:nth-child(6) {
            background: rgba(74, 161, 96, 0.1);
          }
        }

        .table-list {
          width: 100%;
          height: auto;
          display: flex;
          align-content: flex-start;
          justify-content: flex-start;
          flex-wrap: wrap;

          .table-block {
            width: 138px;
            height: 100%;
            border: 1px solid #e8eaec;
            border-top: none;
            border-left: none;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
            color: #000000;
          }

          .long-table-block {
            width: 316px;
          }

          .table-function {
            width: 178px;
            height: auto;

            .function-box {
              width: 100%;
              height: 46px;
              border: 1px solid #e8eaec;
              border-top: none;
              border-left: none;
              box-sizing: border-box;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 14px;
              font-weight: 400;
              color: #000000;
              padding-left: 10px;
              padding-right: 10px;
            }
          }

          .table-edition {
            width: 216px;
            height: auto;

            .edition-box {
              width: 216px;
              height: 46px;
              line-height: 1.4;
              padding-left: 10px;
              padding-right: 10px;
              border: 1px solid #e8eaec;
              border-top: none;
              border-left: none;
              box-sizing: border-box;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 14px;
              font-weight: 400;
              color: #000000;
            }

            .edition-box-right {
              width: 14px;
              height: 12px;
              background: url("../assets/images/vip/right.png") no-repeat;
              background-size: 14px 12px;
            }

            .edition-box-wrong {
              width: 14px;
              height: 12px;
              background: url("../assets/images/vip/wrong.png") no-repeat;
              background-size: 14px 12px;
            }
          }

          .table-edition1 {
            background: rgba(180, 176, 209, 0.04);
          }

          .table-edition2 {
            background: rgba(156, 101, 24, 0.04);
          }

          .table-edition3 {
            background: rgba(60, 76, 237, 0.04);
          }

          .table-edition4 {
            background: rgba(57, 68, 137, 0.04);
          }
          .table-edition5 {
            background: rgba(74, 161, 96, 0.04);
          }

          .interval-box {
            width: 100%;
            height: 28px;
            border: 1px solid #e8eaec;
            border-top: none;
            border-left: none;
            box-sizing: border-box;
          }
        }
      }
    }
  }
  .consulting-modal {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 999;

    .modal-mask {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
    }

    .modal-size {
      width: 498px;
      height: 640px;
      background: #ffffff;
      border-radius: 4px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -320px 0 0 -249px;
      display: flex;
      align-content: flex-start;
      justify-content: center;
      flex-wrap: wrap;

      .modal-close {
        width: 25px;
        height: 25px;
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
      }

      .modal-title {
        width: 100%;
        text-align: center;
        font-size: 28px;
        font-weight: 600;
        color: #3519fb;
        line-height: 40px;
        margin-top: 40px;
      }

      .modal-content {
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        margin: 16px 0 50px 0;
      }

      .modal-item {
        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
        position: relative;

        .item-name {
          width: 105px;
          height: 32px;
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          margin-right: 10px;
          box-sizing: border-box;
        }

        .no-required {
          padding-left: 15px;
        }

        .required::before {
          content: "*";
          display: inline-block;
          margin-right: 7px;
          line-height: 1;
          font-family: SimSun;
          font-size: 14px;
          color: #ed4014;
          font-weight: bold;
        }

        .code-btn {
          width: 110px;
          height: 32px;
          background: #f1f1f1;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.4);
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 14px;
        }

        .code-btn2 {
          width: 110px;
          height: 32px;
          background: rgba(53, 25, 251, 0.1);
          border-radius: 4px;
          font-size: 14px;
          font-weight: 500;
          color: #3519fb;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 14px;
          cursor: pointer;
        }

        .code-btn2:hover {
          background: rgba(53, 25, 251, 0.2);
        }

        .code-time {
          width: 110px;
          height: 32px;
          background: #f1f1f1;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.4);
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 14px;
          position: absolute;
          top: 0;
          right: 51px;
        }
      }

      .modal-btn {
        width: 136px;
        height: 32px;
        background: #3519fb;
        border-radius: 3px;
        margin-top: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}
</style>
