import { render, staticRenderFns } from "./ModalPay.vue?vue&type=template&id=c0f8252a"
import script from "./ModalPay.vue?vue&type=script&lang=js"
export * from "./ModalPay.vue?vue&type=script&lang=js"
import style0 from "./ModalPay.vue?vue&type=style&index=0&id=c0f8252a&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/mnt/www/jenkins/jenkins_home/workspace/product/qianlimu/online/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c0f8252a')) {
      api.createRecord('c0f8252a', component.options)
    } else {
      api.reload('c0f8252a', component.options)
    }
    module.hot.accept("./ModalPay.vue?vue&type=template&id=c0f8252a", function () {
      api.rerender('c0f8252a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ModalPay.vue"
export default component.exports