<template>
  <div v-if="isOpen">
    <Modal
      :value="isOpen"
      class-name="modal-pay-info"
      width="400"
      :closable="true"
      :mask-closable="false"
      footer-hide
      @on-visible-change="handleChangeVisible"
    >
      <div class="order-detail">
        <p v-if="curPayDetail.orderStatus===2" class="success-pay"><Icon type="md-checkmark-circle" />支付成功</p>
        <p v-if="curPayDetail.orderStatus===4" class="error-pay"><Icon type="md-close-circle" />支付失败</p>
        <div v-if="curPayDetail.orderStatus===2">
          成功付款 <span class="total">¥{{curPayDetail.productAmountTotal/100}}</span>，{{curTime}}秒后自动跳转到订单页面
        </div>
        <div v-if="curPayDetail.orderStatus===4">
          支付失败，系统未扣款，请您重新购买
        </div>
        <!-- v-if="curPayDetail.orderStatus===2" -->
        <div  class="btn-group" >
          <Button class="back-btn" @click="backHome">返回首页</Button>
          <Button class="list-btn" @click="toOrderList">查看订单</Button>
        </div>
        <div class="btn-group" v-if="curPayDetail.orderStatus===4">
          <Button class="back-btn" @click="toOrderList">查看订单</Button>
          <Button class="list-btn" @click="payAgain">重新购买</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import {User} from "@/service"
import { openNewWindow } from "@/assets/javascript/utils.js";
export default {
  props:["isShow","curPayDetail"],
  data () {
    return {
      isOpen:false,
      curPayTypeIcon:require("@/assets/images/vip/weixin.png"),
      curTime:5
    }
  },
  watch:{
     isShow(val){
      this.isOpen = val;
      this.getUserInfo();
    },
    isOpen(newval) {
      if (!newval) {
          this.close();
      }
    },
    curPayDetail(val){
      if(val.orderStatus===2){
        this.timer = setInterval(()=>{
          this.curTime --;
          if(this.curTime===-1){
            this.toOrderList()
          }
        },1000)
      }
    }
  },
  methods:{
    async getUserInfo() {
      let res = await User.getUserInfo();
      if (res && res.code === 200) {
        let user = JSON.stringify(res.data.items);
        localStorage.setItem("userInfo", user);
        this.userInfo = res.data.items;
         this.existPreferentialOrder = this.userInfo.userMember.existPreferentialOrder
        if(this.userInfo.userMember.existPreferentialOrder){
          this.payTypeObj = this.payType[0];
          this.payOrderObj = this.payTypeList[1];
          this.activeIndex = 1
        }else{
           this.payTypeObj = this.payType[0];
           this.payOrderObj = this.payTypeList[0];
           this.activeIndex = 0
        }
      }
    },
    close() {
      this.$emit("close");
      clearInterval(this.timer)
      this.timer = null;
      this.curTime = 5;
    },
    handleChangeVisible(tag) {
      if (!tag) {
        this.close();
      }
    },
    backHome(){
      this.$router.push("/")
    },
    toOrderList(){
      this.close()
      this.$router.push("/user?page=myQy&from=vip")
    },
    payAgain(){
      this.$emit("payAgain")
    }
  },
  mounted(){
    if(this.curPayDetail.orderStatus===2){
        this.timer = setInterval(()=>{
          this.curTime --;
          if(this.curTime===1){
            this.toOrderList()
          }
        },1000)
    }
  },

}
</script>
<style  lang="less">
.modal-pay-info {
  display: flex;
  justify-content: center;
  align-items: center;
  .ivu-modal {
    top: 0 !important;
  }
  // .ivu-modal .ivu-modal-content {
  //   width: 400px;
  //   // height: 306px;
  //   background: #fff;
  //   box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  //   // border-radius: 2px;
  //   border: 1px solid rgba(56, 76, 108, 0.8);
  //   backdrop-filter: blur(16px);
  // }
  .ivu-modal-body {
    padding: 30px;
    .order-detail{
      text-align: center;
      p{
        font-size: 28px;
        font-family: 500;
        color: #333333;
        margin-bottom: 20px;
        .ivu-icon{
          font-size: 30px;
          margin-right: 5px;
        }
        .ivu-icon-md-checkmark-circle{
          color: #00BB87;
        }
        .ivu-icon-md-close-circle{
          color: #F21717;
        }
      }
    }
    .btn-group{
      margin-top: 20px;
      button{
        border: none;
        border-radius: 3px;
        margin-top: 8px;
        font-size: 14px;
        font-weight: 400;
        margin-right: 20px;

      }
      .list-btn{
        border: none;
        background: #3519fb;
        color: #ffffff;
      }
      .back-btn{
        background: #ffffff;
        border: 1px solid #dcdee2;
         &:hover{
          color: #3519fb;
        }
      }
    }
    .total{
      color: #F21717;
      font-size: 16px;
      font-family: 500;
    }
  }

}
</style>

